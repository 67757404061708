import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { validateInviteCode } from '../../utils/inviteCode';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { saveAuth, saveRoom, useRoomNavigate } from '../../utils';
import { storeRequestedLocation, useRedirectAfterFinished } from '../../utils/locationRestore';
import { B4StdHeader } from '../common/standard';
import { B4Layout } from '../../design/atomic/layout';

const ME_FRAGMENT = gql`
  fragment EmailSignupMeFragment on User {
    id
    profile_complete
    language
  }
`;
const LOGIN_OR_REGISTER_WITH_OTP_MUTATION = gql`
  mutation ($otp: String!) {
    loginOrRegisterWithOtp(
      otp: $otp
    ) {
        access_token
        refresh_token
        token_type
        expires_in
        user {
          ...EmailSignupMeFragment
        }
      }
    }
    ${ME_FRAGMENT}
`;

const EmailSignup = () => {
  const navigate = useNavigate();
  const roomNavigate = useRoomNavigate();
  const [searchParams] = useSearchParams();
  const otp = searchParams.get('otp');
  const inviteCode = searchParams.get('code');
  const targetLocation = searchParams.get('target_location');
  const client = useApolloClient();
  const redirectAfterFinished = useRedirectAfterFinished();
  const [loginOrRegisterWithOtp] = useMutation(LOGIN_OR_REGISTER_WITH_OTP_MUTATION);

  const effectHandler = async () => {
    if (!otp) {
      navigate('/landing');
    }

    if (targetLocation) {
      storeRequestedLocation(targetLocation);
    }

    if (inviteCode) {
      const tenant = await validateInviteCode(client, inviteCode)
      if (tenant?.id) {
        saveRoom(tenant?.id)
      }
    }
    const { data: { loginOrRegisterWithOtp: loginOrRegisterWithOtpData } } = await loginOrRegisterWithOtp({ variables: { otp: otp } });
    if (loginOrRegisterWithOtpData) {
      saveAuth(loginOrRegisterWithOtpData);
      if (loginOrRegisterWithOtpData.user.profile_complete) {
        redirectAfterFinished();
      } else {
        roomNavigate('/complete');
      }
    } else {
      navigate('/landing'); // We could add an indication that an error happened
    }
  }

  useEffect(() => {
    effectHandler().catch(console.error);
  }, []);

  return (
    <B4Layout header={<B4StdHeader />}>
    </B4Layout>
  );
};

export default EmailSignup;
