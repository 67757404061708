import { animated, easings, useSpring } from "@react-spring/web";
import clsx from "clsx"
import { TouchEvent, useState } from "react";
import { useChangedEffect } from "./utils";
import { B4IconButton } from "./button";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { B4ScreenPartSmall, B4SpaceHorizontal } from "./layout";
import { B4TextTiny } from "./text";
import { t } from "i18next";
import { B4Color } from "./consts";
import { set } from "lodash";

const B4SwitcherElement = ({children}) => (
  <div className="grow-1 shrink-0 basis-full mr-3 flex">{children}</div>
)

interface B4SwitcherProps {
  elements: React.ReactNode[],
  onChange: (index: number) => void,
  current: number,
  disabled?: boolean,
  sticky?: boolean,
  innerClassName?: string
}

export const B4Switcher = ({elements = [], onChange, current, disabled = false, sticky = false, innerClassName = null}: B4SwitcherProps) => {
  const [touchStart, setTouchStart] = useState(0)
  const [touchDelta, setTouchDelta] = useState(0)
  const [waitingForCurrentChange, setWaitingForCurrentChange] = useState(false)

  const calcOverallDelta = (delta: number) => {
    return `calc(${-current * 100}% + ${-current * 12 + delta}px)`
  }
  
  const [style, api] = useSpring(
    () => ({
      from: {translateX: calcOverallDelta(0)},
    }),
    []
  )

  useChangedEffect(() => {
    const translateX = calcOverallDelta(0)
    setWaitingForCurrentChange(false)
    api.start({
      to: {translateX},
      config: {
        duration: 500,
        easing: easings.easeOutSine
      }
    })
  }, [current])

  useChangedEffect(() => {
    if (!waitingForCurrentChange) {
      const translateX = calcOverallDelta(touchDelta)
      api.start({
        to: {translateX},
        immediate: true
      })
    }
  }, [touchDelta])

  const onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setTouchStart(e.touches[0].clientX)
    setTouchDelta(0)
  }

  const onTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    setTouchDelta(e.touches[0].clientX - touchStart)
  }

  const onTouchEnd = (e: TouchEvent<HTMLDivElement>) => {
    if (touchDelta < -25 && current < elements.length - 1) {
      setWaitingForCurrentChange(true)
      onChange(current + 1)
    } else if (touchDelta > 25 && current > 0) {
      setWaitingForCurrentChange(true)
      onChange(current - 1)
    }
    setTouchDelta(0)
    setTouchStart(0)
  }

  const onTouchCancel = (e: TouchEvent<HTMLDivElement>) => {
    setTouchDelta(0)
    setTouchStart(0)
  }

  const onForward = () => {
    if (current < elements.length - 1) onChange(current + 1)
  }

  const onBackward = () => {
    if (current > 0) onChange(current - 1)
  }

  return (
    <>
      {elements.length > 1 && <B4ScreenPartSmall innerClassName="flex items-center">
        <div className="grow basis-0">{ current > 0 && 
          <B4SpaceHorizontal className="items-center cursor-pointer" onClick={onBackward}>
            <B4IconButton disabled={disabled} color={B4Color.GREEN}><MdArrowBack/></B4IconButton>
            <B4TextTiny color={B4Color.GREEN}>{t('lblPrevQuestion')}</B4TextTiny>
          </B4SpaceHorizontal> 
        }</div>
        <div className="grow basis-0 flex justify-end">{ current < elements.length - 1 &&
          <B4SpaceHorizontal className="items-center cursor-pointer" onClick={onForward}>
            <B4TextTiny color={B4Color.GREEN}>{t('lblNextQuestion')}</B4TextTiny>
            <B4IconButton disabled={disabled} color={B4Color.GREEN}><MdArrowForward/></B4IconButton>
          </B4SpaceHorizontal>
        }
        </div>
      </B4ScreenPartSmall>
    }
     
     <B4ScreenPartSmall className={clsx(innerClassName, {
      'sticky top-b4-header z-10': sticky
     })}>
      {disabled ? elements[current] :
        <div className="overflow-x-hidden h-full flex flex-col">
          <animated.div style={style} className="grow flex"
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            onTouchMove={onTouchMove}
            onTouchCancel={onTouchCancel}
          >
            {elements.map((e, i) => (
              <B4SwitcherElement key={i}>{e}</B4SwitcherElement>
            ))}
          </animated.div>
        </div>
      }
      </B4ScreenPartSmall>
    </>
  )
}