import { getAuth, getRoom } from "./auth";

export const getAuthHeader = () => {
  const {access_token} = getAuth();
  return access_token ? `Bearer ${access_token}` : '';
}

export const getBasePath = () => process.env.REACT_APP_API_URL;
export const getApiBasePath = () => `${getBasePath()}/api/`

export const downloadFile = async (path: string) => {
  try {
    const response = await fetch(`${getApiBasePath()}${path}`, {
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'authorization': getAuthHeader(),
        'x-tenant': getRoom()
      }
    });
    const filename = response.headers.get('content-disposition').split('=')[1];
    const blob = await response.blob();
    const fileURL = window.URL.createObjectURL(blob);
    const tmpLink = document.createElement('a');
    tmpLink.href = fileURL;
    tmpLink.download = filename;
    document.body.appendChild(tmpLink); // we need to append the element to the dom -> otherwise it will not work in firefox
    tmpLink.click();
    tmpLink.remove();  //afterwards we remove the element again
    window.URL.revokeObjectURL(fileURL);
  } catch(e) {
    console.error(e);
  }
}