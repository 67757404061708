import { useQuery } from '@apollo/client';
import { graphql } from '../../gql';

export const QUESTION_STATS_QUERY = graphql(/* GraphQL */`
  query GetQuestionStats($conversation_id: ID!) {
    GetQuestionStats(id: $conversation_id) {
      choices
      choices_seed
      choices_new
      choices_duplicates
      choices_avg
      choices_contributors
      prompts_participants
      prompts_done
      prompts_done_avg
      skippers
      regular_skippers
      both_good_skippers
      regular_skips
      both_good_skips
      skips_avg
      regular_skips_avg
      both_good_skips_avg
      voters
      votes
      votes_avg
      total_participants
      excluded_users
    }
  }
`);

const useQuestionStats = (options = {}) => {
  return useQuery(QUESTION_STATS_QUERY, options);
};

export default useQuestionStats;
